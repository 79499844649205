<template>
  <v-container fluid>
    <v-data-table
      :items="items"
      :headers="[
        { text: 'Mannschaft', value: 'team', sortable: false },
        { text: 'Turner', value: 'person', sortable: false },
        { text: 'E', value: 'e', sortable: false },
        { text: 'D', value: 'd', sortable: false },
        { text: 'C', value: 'c', sortable: false },
        { text: 'B', value: 'b', sortable: false },
        { text: 'A', value: 'a', sortable: false },
        { text: 'NE', value: 'ne', sortable: false },
        { text: 'EG', value: 'eg', sortable: false },
        { text: 'Sprung', value: 'sprung', sortable: false },
        { text: 'E1', value: 'e1', sortable: false },
        { text: 'E2', value: 'e2', sortable: false },
        { text: 'E3', value: 'e3', sortable: false },
        { text: 'E4', value: 'e4', sortable: false },
        { text: 'OK', value: 'ok', sortable: false },
        { text: 'End', value: 'final', sortable: false },
      ]"
      hide-default-footer
      :items-per-page="-1"
      @click:row="open"
    >
      <template #item.team="{item}">
        {{ item.team.name }}
      </template>
      <template #item.person="{item}">
        {{ item.person | person }}
      </template>
      <template #item.e="{item}">
        {{ item.result.e | int }}
      </template>
      <template #item.d="{item}">
        {{ item.result.d | int }}
      </template>
      <template #item.c="{item}">
        {{ item.result.c | int }}
      </template>
      <template #item.b="{item}">
        {{ item.result.b | int }}
      </template>
      <template #item.a="{item}">
        {{ item.result.a | int }}
      </template>
      <template #item.ne="{item}">
        {{ item.result.ne | int }}
      </template>
      <template #item.eg="{item}">
        {{ item.result.eg | float1 }}
      </template>
      <template #item.sprung="{item}">
        {{ sprung(item.result._sprung) }}
      </template>
      <template #item.e1="{item}">
        {{ item.result.e1 | float1 }}
      </template>
      <template #item.e2="{item}">
        {{ item.result.e2 | float1 }}
      </template>
      <template #item.e3="{item}">
        {{ item.result.e3 | float1 }}
      </template>
      <template #item.e4="{item}">
        {{ item.result.e4 | float1 }}
      </template>
      <template #item.ok="{item}">
        {{ item.result.ok | float1 }}
      </template>
      <template #item.final="{item}">
        {{ item.result.final | float2 }}
      </template>
    </v-data-table>
    <base-material-dialog
      v-model="result.open"
      icon="far fa-poll-h"
      :title="`Wertung eingeben: ${dname}`"
      :sub-title="`${pname} (${tname})`"
      color="primary"
      @esc="close"
    >
      <ergebnis :id="result.id" :did="did" :e="e" :df="df" @del="del" @save="save" @close="close" />
    </base-material-dialog>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '../../../plugins/calc'
import gql from 'graphql-tag'
import sprungtabelle from '@/views/components/stb_m_2021/content/input/bei/sprungtabelle'

export default {
  name: 'geraet',

  components: {
    Ergebnis: () => import('./ergebnis')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    result: {
      open: false,
      id: null,
      data: null
    },
    windowHeight: window.innerHeight,
    documentHeight: -1,
    resizeObserver: null
  }),

  computed: {
    d () {
      return this.df?.find(d => d._id === this.did) || {}
    },
    height () {
      if (this.f) return this.windowHeight - 260
      const tmp = Math.max(this.windowHeight, this.documentHeight)
      return tmp < 900 ? 500 : tmp - 400
    },
    items () {
      return this.turnerangeraet(this.e, this.r, this.df, this.did).map(t => ({
        type: 'turner',
        person: t.person,
        team: t.team,
        result: t.result,
        final: t.result?.final,
        score: t.result?.score,
        pen: t.result?.penalty,
        order: t.order
      }))
    },
    dname () {
      return this.geraet(this.df, this.result.data?.result?._discipline)?.name
    },
    pname () {
      return this.$options.filters.person(this.result.data?.person)
    },
    tname () {
      return this.result.data?.team?.name
    },
    ori () {
      return this.r?.find(r => r._id === this.result.id) || null
    },
    isScore () {
      return this.e.mode === 'wk_score4'
    }
  },

  methods: {
    open (result) {
      this.result.data = result
      this.result.id = result?.result?._id
      this.result.open = !!result
    },
    onResize () {
      this.windowHeight = window.innerHeight
      this.documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 50
    },
    close () {
      this.open(null)
    },
    async save (data, nonext) {
      const ori = data._id ? this.r.find(r => r._id === data._id) : this.ori

      const cmp = 'ne,a,b,c,d,e,f,g,h,i,parts,eg,e1,e2,e3,e4,ok,_sprung,bonus,dscore,deduction,escore,penalty,final,score'.split(',')

      const neu = {}

      for (const i of cmp) {
        if (ori[i] !== data[i]) neu[i] = data[i]
      }

      neu.id = data._id

      await this.mutate({
        mutation: gql`mutation($id: UUID!, $ne: Int, $a: Int, $b: Int, $c: Int, $d: Int, $e: Int, $f: Int, $g: Int, $h: Int, $i: Int, $parts: Int, $eg: Float, $e1: Float, $e2: Float, $e3: Float, $e4: Float, $ok: Float, $_sprung: Int, $bonus: Boolean, $deduction: Float, $dscore: Float, $escore: Float, $penalty: Float, $final: Float, $score: Int) {
            StbM2021WkTeamAthleteResultUpdate(id: $id, ne: $ne, a: $a, b: $b, c: $c, d: $d, e: $e, f: $f, g: $g, h: $h, i: $i, parts: $parts, eg: $eg, e1: $e1, e2: $e2, e3: $e3, e4: $e4, ok: $ok, sprung: $_sprung, bonus: $bonus, dscore: $dscore, deduction: $deduction, escore: $escore, penalty: $penalty, final: $final, score: $score) { _id }
          }`,
        variables: neu
      })
    },
    async del () {
      if (await this.$root.$children[0].$refs.confirm.open('Wertung löschen?', 'Die Wertung kann nicht wiederhergestellt werden!')) {
        const cmp = 'ne,a,b,c,d,e,f,g,h,i,parts,eg,e1,e2,e3,e4,ok,sprung,bonus,dscore,deduction,escore,penalty,final,score'.split(',')
        const neu = { _id: this.result.id }
        for (const i of cmp) {
          neu[i] = null
        }

        this.save(neu, true)
      }
    },
    sprung (id) {
      return sprungtabelle.find(s => s._id === id)?.ids?.[2023]
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.resizeObserver = new ResizeObserver(this.onResize)
      this.resizeObserver.observe(document.body)
      this.onResize()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    this.resizeObserver.disconnect()
  }
}
</script>

<style scoped>

</style>
